.button {
  --top: var(--xsTop);
  --left: var(--xsLeft);
  --size: var(--ds-size-md);

  /* place the hot spot, centered on its coordinate. The min/max functions prevent hot spots overflowing their parent wrapper */
  --centered-top: min(
    calc(100% - var(--size)),
    max(0%, calc(var(--top) - var(--size) / 2))
  );

  --centered-left: min(
    calc(100% - var(--size)),
    max(0%, calc(var(--left) - var(--size) / 2))
  );

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: solid var(--ds-spacing-5xs) var(--ds-color-core-white);
  transition: background-color, border 0.2s ease-in-out;
  box-shadow: 0px 2px 4px var(--ds-color-transparent-black-900);
  position: absolute;
  z-index: 1;
  opacity: 1;
  top: var(--centered-top);
  left: var(--centered-left);

  &.legacy,
  &.skroll {
    @media (--ds-breakpoint-xs) {
      --top: var(--smTop);
      --left: var(--smLeft);
    }
  }

  &.skroll {
    /* skroll sm breakpoint is 580px */
    @media (--ds-breakpoint-sm) {
      --top: var(--mdTop);
      --left: var(--mdLeft);
    }
  }

  &.legacy {
    /* legacy components use 600px as the sm breakpoint */
    @media screen and (min-width: 600px) {
      --top: var(--mdTop);
      --left: var(--mdLeft);
    }
  }

  &.legacy,
  &.skroll {
    @media (--ds-breakpoint-md) {
      --top: var(--lgTop);
      --left: var(--lgLeft);
    }

    @media (--ds-breakpoint-lg) {
      --top: var(--xlTop);
      --left: var(--xlLeft);
      --size: calc(1.25 * var(--ds-size-md));
    }
  }

  & div {
    /* apply animation transitions to child element, which contains the icon that rotates*/
    transition: transform 0.2s ease-in-out;
  }
}

.transparent {
  background-color: var(--ds-color-transparent-black-500);
}

.transparent.closed:hover {
  transition-duration: 0.2s;
  background-color: var(--ds-color-core-black);
}

.open {
  background-color: var(--ds-color-action-secondary-enabled);
  border: solid var(--ds-spacing-5xs) var(--ds-color-core-black);

  &.animateOpen {
    & div {
      transform: rotate(45deg);
    }
  }
}

.white {
  background-color: var(--ds-color-action-secondary-enabled);
  border: solid var(--ds-spacing-5xs) var(--ds-color-core-black);

  &:hover {
    transition-duration: 0.2s;
    background-color: var(--ds-color-action-secondary-hovered);
  }
}
