/* Opening card animations */
@keyframes openCardBelowLeft {
  from {
    opacity: 0;
    transform: translateX(calc(-100% + var(--button-size))) scale(0);
  }
  to {
    opacity: 1;
    transform: translateX(calc(-100% + var(--button-size))) scale(1);
  }
}

@keyframes openCardBelowRight {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes openCardAboveLeft {
  from {
    opacity: 0;
    transform: translateY(calc(-100% + var(--button-size))) translateX(calc(-100% + var(--button-size))) scale(0);
  }
  to {
    opacity: 1;
    transform: translateY(calc(-100% + var(--button-size))) translateX(calc(-100% + var(--button-size))) scale(1);
  }
}

@keyframes openCardAboveRight {
  from {
    opacity: 0;
    transform: translateY(calc(-100% + var(--button-size))) scale(0);
  }
  to {
    opacity: 1;
    transform: translateY(calc(-100% + var(--button-size))) scale(1);
  }
}

/* Closing card animations */
@keyframes closeCardBelowLeft {
  from {
    opacity: 1;
    transform: translateX(calc(-100% + var(--button-size))) scale(1);
  }
  to {
    transform: translateX(calc(-100% + var(--button-size))) scale(0);
    border-radius: 50%;
  }
}

@keyframes closeCardBelowRight {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
    border-radius: 50%;
  }
}

@keyframes closeCardAboveLeft {
  from {
    opacity: 1;
    transform: translateY(calc(-100% + var(--button-size))) translateX(calc(-100% + var(--button-size))) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(calc(-100% + var(--button-size))) translateX(calc(-100% + var(--button-size))) scale(0);
    border-radius: 50%;
  }
}

@keyframes closeCardAboveRight {
  from {
    opacity: 1;
    transform: translateY(calc(-100% + var(--button-size))) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(calc(-100% + var(--button-size))) scale(0);
    border-radius: 50%;
  }
}

.card {
  --button-size: var(--ds-size-md);
  --top: var(--xsTop);
  --left: var(--xsLeft);
  /*
  Adding display: block does not affect the dialog's visibility when closed.
  The browser uses the open flag to determine whether the dialog is displayed.
  Chromium browsers also add display: none but this breaks transitions on other properties.
  So we override it here.
 */
  display: block;
  position: absolute;
  max-width: 100%;
  /*
  The card is positioned to the top left corner of the button. By default the card animates open to the bottom right of the button.
  The min/max respects the viewport bounds for the button and and keeps the card anchored to the top left of the button. The card itself may overflow the viewport.
  */

  /* when the card is not open the user should not be able to interact with it */
  user-select: none;
  pointer-events: none;

  left: min(
    calc(100% - var(--button-size)),
    max(0%, calc(var(--left) - var(--button-size) / 2))
  );

  top: min(
    calc(100% - var(--button-size)),
    max(0%, calc(var(--top) - var(--button-size) / 2))
  );

  opacity: 0;
  border-radius: var(--ds-border-radius-lg);
  color: var(--ds-color-layer-default);
  margin: 0;
  padding: var(--ds-spacing-3xs);
  outline: 1px solid var(--ds-color-border-neutral-default);
  box-shadow: 0px 0px 4px var(--ds-color-transparent-black-900);

  @media (--ds-breakpoint-xs) {
    --top: var(--smTop);
    --left: var(--smLeft);
  }

  @media (--ds-breakpoint-sm) {
    --top: var(--mdTop);
    --left: var(--mdLeft);
  }

  @media (--ds-breakpoint-md) {
    --top: var(--lgTop);
    --left: var(--lgLeft);
  }

  @media (--ds-breakpoint-lg) {
    --top: var(--xlTop);
    --left: var(--xlLeft);
    --button-size: var(--ds-size-lg);
  }
}

.card.open {
  opacity: 1;
  z-index: 2;
  width: fit-content;
  height: auto;

  /* when the card is open the user should be able to interact with it */
  user-select: all;
  pointer-events: all;

  &.aboveLeft {
    transform-origin: bottom right;
    animation: openCardAboveLeft 0.25s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
      transform: translateY(calc(-100% + var(--button-size))) translateX(calc(-100% + var(--button-size))) scale(1);
    }
  }

  &.aboveRight {
    transform-origin: bottom left;
    animation: openCardAboveRight 0.25s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
      transform: translateY(calc(-100% + var(--button-size))) scale(1);
    }
  }

  &.belowLeft {
    transform-origin: top right;
    animation: openCardBelowLeft 0.25s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
      transform: translateX(calc(-100% + var(--button-size))) scale(1);
    }
  }

  &.belowRight {
    transform-origin: top left;
    animation: openCardBelowRight 0.25s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
      transform: scale(1);
    }
  }
}

.card.hasOpened:not([open]) {
  &.aboveLeft {
    transform-origin: bottom right;
    animation: closeCardAboveLeft 0.2s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  &.aboveRight {
    transform-origin: bottom left;
    animation: closeCardAboveRight 0.2s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  &.belowLeft {
    transform-origin: top right;
    animation: closeCardBelowLeft 0.2s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  &.belowRight {
    transform-origin: top left;
    animation: closeCardBelowRight 0.2s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  & > * {
    visibility: hidden;
  }
}
