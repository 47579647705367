.wrapper {
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: var(--ds-spacing-lg) var(--ds-spacing-2xs);

  & h3 {
    margin: 0;
    margin-bottom: var(--ds-spacing-4xs);
  }

  & p {
    margin: 0;
    margin-bottom: var(--ds-spacing-sm);
  }

  & .noStrapline {
    margin-bottom: var(--ds-spacing-sm);
  }

  &.center h3,
  &.center p {
    text-align: center;
    align-items: center;
  }

  &.right h3,
  &.right p {
    text-align: right;
    align-items: end;
  }
}

.cta {
  width: 100%;
  display: flex;
  justify-content: center;
}
