.galleryGridCardWrapper {
  position: relative;

  & span {
    opacity: 0.9;
  }
}

.imageLinkWrapper {
  width: 100%;
  max-width: 750px;
}

.badgeWrapper {
  position: absolute;
  padding-top: var(--ds-spacing-3xs);
}

.cardImage {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  pointer-events: none;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    user-select: none;
  }
}

.hotSpotButtonWrapper {
  height: auto;
  width: 100%;
}

.hotSpotCardContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 170px;
  max-width: 100%;
  color: var(--ds-color-text-default);

  & a {
    text-decoration: none;
    color: var(--ds-color-text-default);

    & span {
      display: inline-block;
      margin-bottom: var(--ds-spacing-3xs);
    }
  }

  & .priceAndNavigationIcon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & p {
      margin: 0;
    }
  }

  .priceAndNavigationIcon.noPrice {
    justify-content: flex-end;
  }

  &.ugcContent {
    & button {
      margin-left: calc(-1 * var(--ds-spacing-2xs));
      margin-top: calc(-1 * var(--ds-spacing-2xs));
    }
  }
}
