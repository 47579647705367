.gridWrapper {
  width: 100%;
  display: grid;
  gap: var(--ds-spacing-3xs);
  margin-bottom: calc(var(--ds-spacing-sm) + var(--ds-spacing-2xs));

  @media (--ds-breakpoint-lg) {
    gap: var(--ds-spacing-sm);
  }
}

.image {
  display: block;
  width: 100%;
  aspect-ratio: 1;
}

.hero-1,
.hero-2 {
  display: block;
  width: 100%;
  aspect-ratio: 1;
}

.gridWrapper.mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  /* on mobile screens, the dialog should be no wider than its parent list item */
  & li.image dialog {
    max-width: 94%;
  }

  & li.hero-1 dialog,
  & li.hero-2 dialog {
    max-width: calc((100% / 2) - var(--ds-spacing-3xs) - 1.5%);
  }

  &.cards-3 {
    grid-template-rows: repeat(3, 1fr);

    & .hero-1 {
      grid-column: 1 / 3;
      grid-row: 2 / 4;
    }
  }

  &.cards-4 {
    grid-template-rows: repeat(2, 1fr);
  }

  &.cards-5 {
    grid-template-rows: repeat(4, 1fr);

    & .hero-1 {
      grid-column: 1 / 3;
      grid-row: 2 / 4;
    }
  }

  &.cards-6 {
    grid-template-rows: repeat(6, 1fr);

    & .hero-1 {
      grid-column: 1 / 3;
      grid-row: 2 / 4;
    }

    & .hero-2 {
      grid-column: 1 / 3;
      grid-row: 5 / 7;
    }
  }

  &.cards-8 {
    grid-template-rows: repeat(7, 1fr);

    & .hero-1 {
      grid-column: 1 / 3;
      grid-row: 2 / 4;
    }

    & .hero-2 {
      grid-column: 1 / 3;
      grid-row: 5 / 7;
    }
  }

  &.cards-9 {
    grid-template-rows: repeat(4, 1fr);

    & .hero-1 {
      grid-column: 1 / 3;
      grid-row: 3 / 5;
    }
  }

  &.cards-10 {
    grid-template-rows: repeat(5, 1fr);
  }

  &.cards-12 {
    grid-template-rows: repeat(6, 1fr);
  }

  @media (--ds-breakpoint-md) {
    display: none;
  }
}

.gridWrapper.desktop {
  display: none;

  @media (--ds-breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);

    &.cards-3 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }

    &.cards-4 {
      grid-template-rows: 1fr;
    }

    &.cards-5 {
      grid-template-rows: repeat(2, 1fr);

      & .hero-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }
    }

    &.cards-6 {
      & .hero-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      & .hero-2 {
        grid-column: 3 / 5;
        grid-row: 2 / 4;
      }
    }

    /* seven card layout is not supported, we fall back to six cards */

    &.cards-8 {
      grid-template-rows: repeat(2, 1fr);
    }

    &.cards-9 {
      & .hero-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }
    }

    &.cards-10 {
      & .hero-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      & .hero-2 {
        grid-column: 3 / 5;
        grid-row: 3 / 5;
      }
    }

    /* 
    11 card layout is not supported, we fall back to ten cards.
    There is no individual styling required for 12 card layout as it uses four columns and three rows.
    */
  }
}
